$('.seating_chart .reset').on('click', (e) => {
  setTimeout(()=>{
    $(e.target).prop('disabled', true)
  }, 200)
})
  
$('.seating_chart .filter-container .buttons-wrapper .switch').on('click', () => {
  if ($('.seating_chart .reset').prop('disabled') == true) {
    $('.seating_chart .reset').prop('disabled', false)
  }
})
  
document.addEventListener('turbo:load', () => {
  let urlFilters = new URLSearchParams(window.location.search).get('package-group')
  !urlFilters && $('.seating_chart .reset').prop('disabled', true)
})
