import 'bootstrap'
// import './package_category_filter'
//import '../../shared/nav/collapsing_nav'
import '../../shared/phone'
import '../../shared/buyers_page_personalizer'
import './show_hide_content'
import '../../shared/package_comparisons'
import './banner-scroll-effect'
import './gallery-slider'
import '../../default/carousel_bs5'
import './hp-carousel'
import './horizontal_scroll'

import AddToCartForms from '../../shared/add_to_cart_forms'
import HotelIndex from '../../shared/add_to_cart_forms/h4h_hotels/hotel_index'
import { store } from'../../default/store'
import VueLoader from '../../default/vue-loader'
import CartNav from '../../shared/nav/nav_cart'
import Checkout from '../../shared/checkout'
import DepositRedemptions from '../../shared/deposit_redemptions'
import AddToCart from '../../full-gpe/cart/add-to-cart'
import PriceFilter from '../../shared/price_filter'
import './nba_specific_filter'
import './disable_reset'

import './nav'
import '../../shared/hide_packages'
import './url_sorting_params'
import './read_more_dropdowns'

document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  AddToCart.AddToCart()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)


  if(document.getElementById('seating_chart_price')) {
    VueLoader.loadVueComponent(PriceFilter, document.getElementById('seating_chart_price'), store)
  }
})
